import { PasswordlessAuth } from "@localkitchens/passwordless-auth";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { initDefaultOpsApiClient } from "opsapi/api";
import React, { useEffect, useMemo, useState } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { ApolloWrapper } from "./app/ApolloWrapper";
import { EmployeeAuth } from "./app/EmployeeAuth";
import * as serviceWorker from "./serviceWorker";
import "./tailwind.output.css";

const authOrigin = process.env.REACT_APP_AUTH_ORIGIN || "";
const opsBackendUrl = process.env.REACT_APP_AUTH_ORIGIN || "";
const googleClientId = process.env.REACT_APP_GOOGLE_OATH_CLIENT_ID || "";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: true,
    },
  },
});

initDefaultOpsApiClient({ opsBackendUrl });

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}

type TrackingEvent = {
  event: string;
  properties?: object;
};

type TrackState = {
  track: TrackingHandler;
};
type TrackingHandler = (trackingEvent: TrackingEvent) => void;

const track = () => {};
const { identify } = window.analytics;

const AppWrapper = () => {
  // Enables receiving a tracking handler from a child component. Tracking
  // requires data from graphql which will not be populated at
  // the time of rendering this component.
  const [tracker, setTrackingHandler] = useState<TrackState>({ track });

  const trackingHandler = useMemo(() => tracker.track, [tracker]);

  useEffect(() => {
    if (
      !window.location.href.includes(".com") &&
      !window.location.href.includes(".kitchen") &&
      !window.location.href.includes(".dev")
    ) {
      const url = window.location.href.replace(".co", ".com");
      window.location.href = url;
    }
  }, []);

  return (
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <PasswordlessAuth
          authOrigin={authOrigin}
          googleClientId={googleClientId}
          track={trackingHandler}
          identify={identify}
        >
          <ApolloWrapper>
            <EmployeeAuth setTrackingHandler={setTrackingHandler} />
          </ApolloWrapper>
        </PasswordlessAuth>
      </QueryClientProvider>
    </BrowserRouter>
  );
};

const rootContainer = document.getElementById("root");
if (!rootContainer) {
  throw new Error("Root container not found");
}
const root = ReactDOM.createRoot(rootContainer);
root.render(
  <React.StrictMode>
    <AppWrapper />
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

export type { TrackState, TrackingEvent, TrackingHandler };
