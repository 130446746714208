import { useAuth } from "@localkitchens/passwordless-auth";
import React, { useContext, useEffect, useState } from "react";
import { IdleTimerProvider } from "react-idle-timer";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { StatsigProvider } from "statsig-react";
import { BusinessManagerController } from "../features/BusinessManager";
import { EmployeeLoginModal } from "../features/EmployeeLogin";
import { IdleLogoutModal } from "../features/EmployeeLogin/components/IdleLogoutModal";
import { KiosksController } from "../features/Kiosks";
import { Logout } from "../features/Logout";
import { MenuManagerController } from "../features/MenuManager";
import { OrderManagerController } from "../features/OrderManager";
import { Settings } from "../features/Settings";
import { useLocationAccount } from "../hooks/useLocationAccount";
import { ToastProvider } from "../hooks/useToast";
import { DesktopNav, MobileNav } from "../ui";
import { EmployeeContext } from "../utils/contexts/EmployeeContext";
import { IDDLE_SESSION_TIMEOUT } from "../utils/iddleTimeoutContants";
import { hasRole } from "../utils/userUtils";
import "./App.css";
import { routes } from "./routes";

export const App = () => {
  const {
    isAuthenticated,
    user,
    logout: passwordlessLogout,
    login,
    isLoading,
  } = useAuth();

  const {
    employee,
    showIdleLogoutModal,
    onEmployeeAuthentication,
    onEmployeeLogout,
  } = useContext(EmployeeContext);

  const routeLocation = useLocation();
  const activePath = routeLocation.pathname;
  const searchParams = new URLSearchParams(routeLocation.search);
  const locationSlug = searchParams.get("location");
  const hide = searchParams.get("hide");
  const [currentLocation, setCurrentLocation] = useState<string>(
    locationSlug || "lafayette"
  );
  const [currentVersion, setCurrentVersion] = useState<string | undefined>(
    undefined
  );
  const [isAdmin, setIsAdmin] = useState(false);
  const { data: locationAccountData } = useLocationAccount(user?.email);

  useEffect(() => {
    setIsAdmin(hasRole("ADMIN", user));

    if (hasRole("KITCHEN", user)) {
      const newSlug =
        locationAccountData?.location_account?.location?.slug ??
        currentLocation;
      onLocationChange(newSlug);
    }

    if (user?.email) {
      analytics.identify(user.email, {
        name: user.name,
        nickname: user.nickname,
        email: user.email,
        emailVerified: user.email_verified,
        sub: user.sub,
      });
    }
  }, [user, locationAccountData]);

  useEffect(() => {
    const setVersion = async () => {
      const res = await fetch("/version.txt");
      const version = await res.text();
      setCurrentVersion(version);
    };
    setVersion();
  }, []);

  useEffect(() => {
    const interval = setInterval(async () => {
      const res = await fetch("/version.txt");
      const newVersion = await res.text();

      if (currentVersion !== newVersion) {
        window.location.reload();
      }
    }, 30 * 1000);
    return () => clearInterval(interval);
  }, [currentVersion]);

  useEffect(() => {
    analytics.page();
  }, [routeLocation]);

  const onLocationChange = (location: string) => {
    setCurrentLocation(location);
  };

  const logout = (args: any) => {
    analytics.track("Logged Out", {
      ...args,
    });
    passwordlessLogout(args);
  };

  const onIdle = () => {
    if (employee) {
      onEmployeeLogout(locationAccountData?.location_account?.location?.id);
    }
  };

  if (isLoading) {
    return (
      <div className="bg-lfg-secondary h-screen">
        <div className="flex items-center justify-center h-full flex-col">
          <div className="mb-24">Loading...</div>
        </div>
        <img
          className="w-full absolute bottom-0"
          src="https://lfg-landing.s3-us-west-2.amazonaws.com/sf_alt.png"
        />
      </div>
    );
  }

  if (!isAuthenticated) {
    return (
      <div className="bg-lfg-secondary h-screen">
        <div className="flex items-center justify-center h-full flex-col">
          <button
            className="z-50 mb-24 bg-lfg-primary text-lfg-secondary font-bold uppercase px-12 py-6 rounded"
            onClick={() => {
              analytics.track("Clicked Login");
              login();
            }}
          >
            Login
          </button>
        </div>
        <img
          className="w-full absolute bottom-0"
          src="https://lfg-landing.s3-us-west-2.amazonaws.com/sf_alt.png"
        />
      </div>
    );
  }

  return (
    <StatsigProvider
      sdkKey={process.env.REACT_APP_STATSIG_CLIENT_SECRET || ""}
      user={{
        userID: user?.email,
        custom: {
          provider: "kitchen",
          location: currentLocation ?? "",
        },
      }}
      options={{
        environment: {
          tier:
            process.env.NODE_ENV === "production"
              ? "production"
              : "development",
        },
      }}
      waitForInitialization={false}
    >
      <IdleTimerProvider timeout={IDDLE_SESSION_TIMEOUT} onIdle={onIdle}>
        <div className="bg-gray-100 flex">
          {!hide && (
            <DesktopNav
              logo="/logos/lfg.png"
              routes={routes}
              pathname={activePath}
              isTight
              isAuthenticated={isAuthenticated}
              logout={logout}
              loginWithRedirect={login}
              user={user}
              employee={employee}
              locationId={locationAccountData?.location_account?.location?.id}
              currentLocation={currentLocation}
            />
          )}
          <div className="relative w-full flex flex-col h-screen overflow-y-hidden">
            {!hide && (
              <MobileNav
                user={user}
                logo="/logos/lfg.png"
                routes={routes}
                pathname={activePath}
                isAuthenticated={isAuthenticated}
                currentLocation={currentLocation}
                logout={logout}
                loginWithRedirect={login}
              />
            )}
            <ToastProvider>
              <div className="w-full h-screen overflow-x-hidden border-t flex flex-col">
                <main className="w-full grow">
                  <Switch>
                    <Route exact path="/">
                      <OrderManagerController
                        currentLocation={currentLocation}
                      />
                    </Route>
                    <Route exact path="/order-manager">
                      <OrderManagerController
                        currentLocation={currentLocation}
                      />
                    </Route>
                    <Route exact path="/menu">
                      <MenuManagerController
                        currentLocation={currentLocation}
                      />
                    </Route>
                    <Route exact path="/kiosks">
                      <KiosksController currentLocation={currentLocation} />
                    </Route>
                    <Route exact path="/business-manager">
                      <BusinessManagerController
                        currentLocation={currentLocation}
                      />
                    </Route>
                    <Route exact path="/settings">
                      <Settings currentLocation={currentLocation} />
                    </Route>
                    <Route exact path="/logout">
                      <Logout logout={logout} />
                    </Route>
                    <Redirect to="/" />
                  </Switch>
                </main>
              </div>
            </ToastProvider>
          </div>
          {!employee && !isAdmin && (
            <EmployeeLoginModal
              locationId={locationAccountData?.location_account?.location?.id}
              onEmployeeAuthentication={onEmployeeAuthentication}
            />
          )}
          {showIdleLogoutModal && employee && (
            <IdleLogoutModal
              locationId={locationAccountData?.location_account?.location?.id}
            />
          )}
        </div>
      </IdleTimerProvider>
    </StatsigProvider>
  );
};
